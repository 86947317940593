@tailwind base;
@tailwind components;
@tailwind utilities;
@import './identity-utilities.scss';

Html {
  @apply antialiased;
  @apply h-full;
}

#root {
  @apply flex flex-col h-full;
}

.whiteSecondaryGradient {
  background: linear-gradient(180deg, #ffffff 0%, #cbd3f0 100%);
}

.transparentBlackGradient {
  background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 79.69%);
}

.popup-overlay {
  background-color: rgba(112, 112, 112, 0.7);
}

.sls-top-page-poster__gradient {
  @media (max-width: 1900px) {
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 79.69%);
  }
  @media (min-width: 1900px) {
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 50%),
      linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.3) 3%, rgba(0, 0, 0, 0) 12%),
      linear-gradient(270deg, #000000 0%, rgba(0, 0, 0, 0.3) 3%, rgba(0, 0, 0, 0) 12%);
  }
  transform: rotate(-180deg);
}

.default-heading {
  @apply text-4xl 3xl:text-6xl md:text-5xl font-headline;
}

.default-body {
  @apply text-base md:text-lg lg:text-xl xl:text-2xl 3xl:text-3xl font-arial;
}

.partner-scroll::-webkit-scrollbar {
  @apply bg-white w-2;
}

.partner-scroll::-webkit-scrollbar-thumb {
  @apply bg-primary-100;
}

.position-scroll::-webkit-scrollbar {
  @apply bg-primary-100 w-2;
}

.position-scroll::-webkit-scrollbar-thumb {
  @apply bg-primary-400;
}
